import { formatMoney } from "_assets/js/helpers";
/**
 * Calculates VDC fee based on loan amount
 * @param {number} loanAmount - The loan amount
 * @returns {number} The calculated VDC fee
 */
export const calculateVDCFee = (loanAmount) => {
  let feePercentage;

  switch (true) {
    case loanAmount >= 2000:
      feePercentage = 50;
      break;
    case loanAmount >= 1900:
      feePercentage = 51;
      break;
    case loanAmount >= 1800:
      feePercentage = 52;
      break;
    case loanAmount >= 1700:
      feePercentage = 53;
      break;
    case loanAmount >= 1600:
      feePercentage = 54;
      break;
    case loanAmount >= 1300:
      feePercentage = 55;
      break;
    case loanAmount >= 1200:
      feePercentage = 56;
      break;
    case loanAmount >= 1100:
      feePercentage = 57;
      break;
    case loanAmount >= 1000:
      feePercentage = 58;
      break;
    default:
      feePercentage = 58;
  }

  return loanAmount * (feePercentage / 100);
};

/**
 * Calculates monthly VDC payment
 * @param {number} vdcFee - The VDC fee amount
 * @param {number} term - The term in months (default 12)
 * @returns {number} The monthly VDC payment
 */
export const calculateVDCPayment = (vdcFee, term = 12) => {
  return Number((vdcFee / term).toFixed(2));
};

/**
 * Calculates tiered interest rate
 * @param {number} loanAmount - The loan amount
 * @param {number} tier1 - First tier rate (default 0.36)
 * @param {number} tier2 - Second tier rate (default 0.30)
 * @param {number} tier3 - Third tier rate (default 0.24)
 * @returns {number} The calculated interest rate
 */
export const calculateRate = (
  loanAmount,
  tier1 = 0.36,
  tier2 = 0.3,
  tier3 = 0.24
) => {
  const principal = Number((76.75 + loanAmount * 1.0035).toFixed(2));
  let intRate;

  if (principal <= 10000) {
    intRate = tier1;
  } else if (principal <= 20000) {
    intRate = (tier1 * 10000 + tier2 * (principal - 10000)) / principal;
  } else {
    intRate =
      (tier1 * 10000 + tier2 * 10000 + tier3 * (principal - 20000)) / principal;
  }

  return intRate;
};

/**
 * Calculates monthly principal and interest payment
 * @param {number} loanAmount - The loan amount
 * @param {number} intRate - The interest rate
 * @returns {number} The monthly P&I payment
 */
export const calculateMonthlyPIPayment = (loanAmount, intRate) => {
  const principal = Number((76.75 + loanAmount * 1.0035).toFixed(2));
  const pRate = intRate / 12;
  const pmtFactor =
    (Math.pow(1 + pRate, 12) - 1) / (pRate * Math.pow(1 + pRate, 12));
  return Number((principal / pmtFactor).toFixed(2));
};

/**
 * Calculates total monthly payment including VDC if selected
 * @param {number} loanAmount - The loan amount
 * @param {boolean} includeVDC - Whether to include VDC payment
 * @returns {number} The total monthly payment
 */
export const calculateTotalPayment = (loanAmount, includeVDC = true) => {
  const rate = calculateRate(loanAmount);
  let totalPayment = calculateMonthlyPIPayment(loanAmount, rate);

  let vdcFee = 0;
  let vdcPayment = 0;
  if (includeVDC) {
    vdcFee = calculateVDCFee(loanAmount);
    vdcPayment = calculateVDCPayment(vdcFee);
    totalPayment += vdcPayment;
  }

  const financeCharge =
    12 * calculateMonthlyPIPayment(loanAmount, rate) -
    Number(76.75 + loanAmount * 1.0035);

  return {
    "Total Payment": formatMoney(totalPayment),
    "Finance Charge": formatMoney(financeCharge),
    // Rate: formatPercent(rate),
    "VDC Fee": formatMoney(vdcFee),
    "VDC Payment": formatMoney(vdcPayment),
  };
};

import { useState } from "react";
import { Modal } from "@centrate-io/barn";
import moment from "moment";

function PolicyModal(props) {
  const [visible, setVisible] = useState(true);
  return (
    <Modal
      wrapClassName="policy-modal"
      open={visible}
      title={null}
      footer={null}
      onCancel={() => setVisible(false)}
      afterClose={props.removeModal}
      destroyOnClose={true}
      centered
      closable={true}
      maskClosable={false}
      width={500}
    >
      <Modal.Close onClick={() => setVisible(false)} />
      <Modal.Body>
        <h1>Privacy Policy</h1>
        <p>
          <strong>Effective Date:</strong> {moment().format("MMMM Do, YYYY")}
        </p>
        <p>
          Sky Auto Finance ("we," "our," "us") values your privacy and is
          committed to protecting your personal information. This Privacy Policy
          outlines how we collect, use, and protect your data when you use our
          services.
        </p>

        <h2>1. Information We Collect</h2>
        <p>
          We collect the following types of information from you when you use
          our services:
        </p>
        <h3>Verification Information</h3>
        <ul>
          <li>
            <strong>Phone:</strong> Your phone number for identity verification
            and communication purposes.
          </li>
        </ul>
        <h3>Quote Information</h3>
        <ul>
          <li>
            <strong>Up-to Amount:</strong> The loan amount you are eligible for
            pre-approval.
          </li>
          <li>
            <strong>Call Requested:</strong> Whether you have requested a call
            for further assistance.
          </li>
        </ul>
        <h3>Personal Information</h3>
        <ul>
          <li>
            <strong>First Name and Last Name:</strong> Your name to personalize
            our services.
          </li>
          <li>
            <strong>Email:</strong> Your email address to communicate with you.
          </li>
          <li>
            <strong>Amount:</strong> Loan amount requested by you.
          </li>
          <li>
            <strong>Policy Agreement:</strong> Confirmation that you have agreed
            to this Privacy Policy.
          </li>
        </ul>
        <h3>Location Information</h3>
        <ul>
          <li>
            <strong>Address, City, State, and Zip:</strong> Your residential
            details to assess loan eligibility and underwriting requirements.
          </li>
        </ul>
        <h3>Car Information</h3>
        <ul>
          <li>
            <strong>Make, Model, Year, Series, Style, Mileage, and VIN:</strong>{" "}
            Details about your vehicle to evaluate your loan application.
          </li>
        </ul>

        <h2>2. How We Use Your Information</h2>
        <p>We use your information for the following purposes:</p>
        <ul>
          <li>
            <strong>Loan Pre-Approval and Underwriting:</strong> To evaluate
            your eligibility for a loan and provide loan quotes.
          </li>
          <li>
            <strong>Communication:</strong> To contact you regarding your loan
            application or respond to your inquiries.
          </li>
          <li>
            <strong>Marketing Services:</strong> To create digital
            advertisements targeting audiences who may benefit from our
            services. Note: All data used for marketing purposes is anonymized
            and aggregated. We do not resell or share your personal data with
            third parties for marketing purposes.
          </li>
        </ul>

        <h2>3. Data Retention</h2>
        <p>
          We retain your data for up to 10 years to comply with legal and
          regulatory requirements and provide continued services. After this
          period, your data will be securely deleted or anonymized.
        </p>

        <h2>4. Your Rights</h2>
        <p>
          You have the following rights regarding your personal information:
        </p>
        <ul>
          <li>
            <strong>Access:</strong> You can request a copy of the data we have
            collected about you.
          </li>
          <li>
            <strong>Correction:</strong> You can request that we correct any
            inaccuracies in your data.
          </li>
          <li>
            <strong>Deletion:</strong> You can request that we delete your data
            by contacting us at the email or phone number below.
          </li>
        </ul>

        <h2>5. How to Contact Us</h2>
        <p>
          If you have any questions or concerns about this Privacy Policy or
          would like to exercise your rights, please contact us:
        </p>
        <ul>
          <li>
            <strong>Email:</strong>{" "}
            <a href="mailto:info@skyautofinance.com">info@skyautofinance.com</a>
          </li>
          <li>
            <strong>Phone:</strong> (954) 836-9299
          </li>
        </ul>

        <h2>6. Data Security</h2>
        <p>
          We implement reasonable technical and organizational measures to
          protect your personal information from unauthorized access, loss, or
          misuse. However, no system can guarantee absolute security. If you
          suspect any unauthorized use of your data, please contact us
          immediately.
        </p>

        <h2>7. Updates to This Policy</h2>
        <p>
          We may update this Privacy Policy from time to time to reflect changes
          in our practices or legal requirements. Any updates will be posted on
          our website with a revised effective date.
        </p>

        <h2>8. Consent</h2>
        <p>
          By using our services, you consent to the collection and use of your
          information as described in this Privacy Policy.
        </p>
      </Modal.Body>
    </Modal>
  );
}

export default PolicyModal;

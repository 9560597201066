import React, { useState } from "react";
import {
  Actions,
  Autocomplete,
  Break,
  Header,
  Input,
  InputNumber,
  Label,
  Select,
  Progress,
  Subheader,
} from "_styleguide";
import { FormatPhone } from "_assets/js/helpers";
import { getForm } from "_helpers/form";
import { STATES } from "_helpers/states";

function PersonalInformation(props) {
  const [form, setForm] = useState(getForm("personal"));
  const [address, setAddress] = useState(getForm("location"));

  // Update form by field and value
  const ua = (values) => setAddress({ ...address, ...values });

  // Update form by field and value
  const u = (f, v) => {
    const n = { ...form };
    n[f] = f === "phone" ? FormatPhone(v) : v;
    setForm(n);
  };

  const isValid =
    form.first_name &&
    form.last_name &&
    form.email &&
    address.address &&
    address.city &&
    address.state &&
    address.zip &&
    parseInt(form?.amount || 0) > 0
      ? true
      : false;

  return (
    <div className="step-start">
      <Progress
        className="sky-progress"
        percent={props.progress}
        showInfo={false}
      />
      <Header>Personal Information</Header>
      <Subheader>Tell us about yourself</Subheader>
      <div className="app-form">
        <div className="form-row">
          <div>
            <Label htmlFor="first_name">First name</Label>
            <Input
              id="first_name"
              value={form.first_name}
              onChange={(e) => u("first_name", e.target.value)}
              size="large"
            />
          </div>
          <div>
            <Label htmlFor="last_name">Last name</Label>
            <Input
              id="last_name"
              value={form.last_name}
              onChange={(e) => u("last_name", e.target.value)}
              size="large"
            />
          </div>
        </div>
        <div className="form-item">
          <Label htmlFor="email">Email address</Label>
          <Input
            id="email"
            value={form.email}
            onChange={(e) => u("email", e.target.value)}
            size="large"
          />
        </div>
        <div className="form-item">
          <Label htmlFor="address">Address</Label>
          <Autocomplete
            value={address.address}
            onChange={(v) => ua({ address: v })}
            updateAddress={ua}
          />
        </div>
        <div className="form-row">
          <div>
            <Label htmlFor="city">City</Label>
            <Input
              id="city"
              value={address.city}
              onChange={(e) => ua({ city: e.target.value })}
              size="large"
            />
          </div>
          <div style={{ maxWidth: "140px" }}>
            <Label htmlFor="state">State</Label>
            <Select
              value={address.state}
              onChange={(v) => ua({ state: v })}
              style={{ width: "100%" }}
              options={STATES.map((m) => ({ value: m.value, label: m.label }))}
              size="large"
            />
          </div>
          <div style={{ maxWidth: "100px" }}>
            <Label htmlFor="zip">Zip</Label>
            <Input
              id="zip"
              value={address.zip}
              onChange={(e) => ua({ zip: e.target.value })}
              size="large"
            />
          </div>
        </div>
      </div>
      <Break />
      <Subheader>How much are you looking for?</Subheader>
      <div className="app-form">
        <div className="form-item">
          <Label htmlFor="amount">Loan Amount</Label>
          <InputNumber
            id="amount"
            min={1}
            max={10000000}
            formatter={(value) =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            style={{ width: "100%" }}
            value={form.amount}
            onChange={(v) => u("amount", v)}
            size="large"
          />
        </div>
      </div>
      <Actions
        {...props}
        nextDisabled={!isValid}
        form={{ personal: form, location: address }}
        application={["zoho"]}
      />
    </div>
  );
}

export default PersonalInformation;

export const FormatPhone = (nput) => {
  let newValue = "";
  if (nput[0] === "+" && nput[1] === "1") nput = nput.replace("+1", "");
  const input = nput.replace(/\D/g, "").substring(0, 10);
  const zip = input.substring(0, 3);
  const middle = input.substring(3, 6);
  const last = input.substring(6, 10);

  if (input.length > 6) {
    newValue = `${zip}-${middle}-${last}`;
  } else if (input.length === 6) {
    newValue = `${zip}-${middle}`;
  } else if (input.length > 3) {
    newValue = `${zip}-${middle}`;
  } else if (input.length === 3) {
    newValue = `${zip}`;
  } else if (input.length > 0) {
    newValue = `${zip}`;
  }
  return newValue;
};

export const formatMoney = (amount) =>
  new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(
    amount || 0
  );

export const formatPercent = (decimal) => {
  return `${(decimal * 100).toFixed(2)}%`;
};

export const flatMoney = (amount) => {
  const val = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(amount || 0);
  return val.split(".")[0];
};

export const timeout = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const formatExpiration = (value) => {
  // Remove all non-digit characters
  value = value.replace(/\D/g, "");

  // Add a "/" after the second digit
  if (value.length > 2) {
    value = value.slice(0, 2) + "/" + value.slice(2, 4);
  }

  // Limit output to MM/YY format
  return value.slice(0, 5);
};

export const FormatSSN = (nput) => {
  let newValue = "";
  if (nput[0] === "+" && nput[1] === "1") nput = nput.replace("+1", "");
  const input = nput.replace(/\D/g, "").substring(0, 10);
  const zip = input.substring(0, 3);
  const middle = input.substring(3, 5);
  const last = input.substring(5, 9);

  if (input.length > 5) {
    newValue = `${zip}-${middle}-${last}`;
  } else if (input.length === 5) {
    newValue = `${zip}-${middle}`;
  } else if (input.length > 3) {
    newValue = `${zip}-${middle}`;
  } else if (input.length === 3) {
    newValue = `${zip}`;
  } else if (input.length > 0) {
    newValue = `${zip}`;
  }
  return newValue;
};

export const stripHTML = (html) => {
  const div = document.createElement("div");
  div.innerHTML = html;
  const text = div.textContent || div.innerText || "";
  return text;
};

export const delay = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const capitalize = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const flatten = (data) => {
  const newObject = {};
  Object.keys(data).forEach((key) => {
    const tempObject = data[key];
    Object.keys(tempObject).forEach((field) => {
      newObject[field] = tempObject[field];
    });
  });
  return newObject;
};

export const parseAddress = (components) => {
  const now = {};
  components.forEach((c) => {
    now[c.types[0]] = {
      long: c.long_name,
      short: c.short_name,
    };
  });
  return now;
};

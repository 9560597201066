import React, { useState } from "react";
import {
  Header,
  Input,
  InputNumber,
  Label,
  Subheader,
  Button,
  Checkbox,
} from "_styleguide";
import axios from "axios";
import { Collapse } from "antd";
import { calculateTotalPayment } from "./_helpers";
import "./Calculator.scss";

function Calculator(props) {
  const [vin, setVin] = useState();
  const [miles, setMiles] = useState();
  const [loanAmount, setLoanAmount] = useState();
  const [vdc, setVdc] = useState(true);
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState();
  const [loanResults, setLoanResults] = useState();

  const checkVin = async () => {
    if (!vin || !miles) return;
    try {
      setLoading(true);
      const saveResults = await axios.post(
        `${process.env.REACT_APP_API_PATH}/blackbook-calculator`,
        {
          vin: vin,
          mileage: miles,
        }
      );
      const blackbookData = saveResults?.data?.blackbookData;
      if (!blackbookData) return;
      setResults(blackbookData);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const calculateLoan = () => {
    const totalPayment = calculateTotalPayment(loanAmount, vdc);
    setLoanResults(totalPayment);
  };

  return (
    <div className="step-car-calculator">
      <Header>Calculators</Header>
      <Subheader>
        Use the calculators below to get an estimates of vehicle value and loan
        information
      </Subheader>
      <Collapse defaultActiveKey={["1"]}>
        <Collapse.Panel header="Blackbook Calculator" key="1">
          <div className="app-form">
            <div className="form-item">
              <Label htmlFor="vin">VIN #</Label>
              <Input
                id="vin"
                style={{ width: "100%" }}
                value={vin}
                onChange={(e) => setVin(e.target.value?.toUpperCase())}
                size="large"
              />
            </div>
            <div className="form-item">
              <Label htmlFor="mileage">Est. Current Mileage</Label>
              <InputNumber
                id="mileage"
                min={1}
                max={10000000}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                style={{ width: "100%" }}
                value={miles}
                onChange={(v) => setMiles(v)}
                size="large"
              />
            </div>
          </div>
          {results ? (
            <div className="blackbook-results">
              <h2>Results</h2>
              {Object.keys(results).map((key) => (
                <div className="results-item">
                  <span>
                    {key}:&nbsp;&nbsp;&nbsp;<strong>{results[key]}</strong>
                  </span>
                </div>
              ))}
            </div>
          ) : null}
          <Button
            loading={loading}
            type="primary"
            size="large"
            block
            onClick={checkVin}
          >
            Calculate Blackbook
          </Button>
        </Collapse.Panel>

        <Collapse.Panel header="Loan Estimate Calculator" key="2">
          <div className="app-form">
            <div className="form-item">
              <Label htmlFor="loan-amount">Loan Amount</Label>
              <InputNumber
                id="loan-amount"
                min={1}
                formatter={(value) =>
                  `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                style={{ width: "100%" }}
                value={loanAmount}
                onChange={(v) => setLoanAmount(v)}
                size="large"
              />
            </div>
            <div className="form-item">
              <Checkbox
                id="vdc"
                checked={vdc}
                onChange={(e) => setVdc(e.target.checked)}
                style={{ marginRight: "8px" }}
              />
              Use VDC?
            </div>
          </div>
          {loanResults ? (
            <div className="blackbook-results">
              <h2>Results</h2>
              {Object.keys(loanResults).map((key) => (
                <div className="results-item">
                  <span>
                    {key}:&nbsp;&nbsp;&nbsp;<strong>{loanResults[key]}</strong>
                  </span>
                </div>
              ))}
            </div>
          ) : null}
          <Button type="primary" size="large" block onClick={calculateLoan}>
            Calculate Quote
          </Button>
        </Collapse.Panel>
      </Collapse>
    </div>
  );
}

export default Calculator;

import axios from "axios";

export const saveApplication = async (cms, application) => {
  console.log(cms, application);
  let saveResults = null;
  const data = {
    id: localStorage.getItem("sky_form_id") || null,
    application: application,
    cms: cms,
  };
  try {
    saveResults = await axios.post(
      `${process.env.REACT_APP_API_PATH}/application`,
      data
    );
    if (saveResults?.data?.form_id) {
      localStorage.setItem("sky_form_id", saveResults?.data?.form_id);
    }
  } catch (err) {
    console.log(err);
  }

  return data;
};

const formStructure = {
  verification: {
    phone: undefined,
    verified: false,
  },
  quote: {
    result: undefined,
    up_to_amount: undefined,
    quote_amount: undefined,
    call_requested: undefined,
  },
  personal: {
    first_name: undefined,
    last_name: undefined,
    email: undefined,
    amount: undefined,
    payment_type: "bank",
    policy: false,
  },
  location: {
    address: undefined,
    city: undefined,
    state: undefined,
    zip: undefined,
    ownership: false,
  },
  car: {
    make: undefined,
    model: undefined,
    year: undefined,
    series: undefined,
    style: undefined,
    uvc: undefined,
    mileage: undefined,
    vin: undefined,
    tradein: 0,
    hotsale: 0,
  },
  income: {
    ssn: undefined,
    ssn_confirm: undefined,
    employer: undefined,
    gross_pay: undefined,
    pay_frequency: undefined,
  },
  bank: {
    bank_name: undefined,
    account_type: undefined,
    routing_number: undefined,
    account_number: undefined,
  },
  debit: {
    card_name: undefined,
    card_number: undefined,
    card_expiration: undefined,
    cvv: undefined,
    billing_zip: undefined,
  },
  identification: {
    id_type: undefined,
    id_number: undefined,
    issue_date: undefined,
    expiration_date: undefined,
    id_state: undefined,
  },
};

export const hasCategory = (category) => {
  return formStructure[category] ? true : false;
};

export const getForm = (category) => {
  let currentForm = undefined;

  // Attempt to grab form data or use blank form
  try {
    const parsedForm = JSON.parse(localStorage.getItem("sky_form"));
    currentForm = parsedForm || Object.assign({}, formStructure);
  } catch (err) {
    currentForm = Object.assign({}, formStructure);
  }

  // Return entire form or just a part depending on what is requested
  if (category) {
    return currentForm[category] || formStructure[category];
  } else {
    return currentForm;
  }
};

export const saveForm = (data, category) => {
  let currentForm = getForm();
  if (category) {
    currentForm = {
      ...formStructure,
      ...currentForm,
      ...data,
    };
  } else {
    currentForm = Object.assign({}, data);
  }
  localStorage.setItem("sky_form", JSON.stringify(currentForm));
  return currentForm;
};
